import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, InputAdornment, Tab, Tabs, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TabContext, TabPanel } from "@mui/lab";
import {getAllBuyerBookings} from "../../components/bookedSpaces/request/booked-spaces-requests";
import { withSnackbar } from "../../components/hocs/withSnackbar";
import { getErrorMessageForNonStandardAndStandardResponse } from "../../util/NetworkErrorUtil";
import ActiveBookings from "../../components/bookingsOverview/ActiveBookings";
import PendingBookings from '../../components/bookingsOverview/PendingBookings';
import { Search } from "@mui/icons-material";
import type { Booking } from "../../types/Booking";
import type { Account } from "../../types/Account";
import BuyerBookingTab from "../../components/bookingsOverview/BuyerBookingTab";
import { BuyerBookingTabs } from "../../components/bookingsOverview/data";
import { formatCurrencyValue } from '../../util/PaymentUtils';
import { formatFrequency } from '@securspace/securspace-ui-kit';
import moment from 'moment';

export const showEndDate = (data) => {
  const {endDateAdjusted, bookingSubscriptionStatus, frequency} = data;
  const CANCELLED = 'CANCELLED';
  const RECURRING = 'RECURRING';

  const isOneTime = frequency !== RECURRING;
  const isCancelled = bookingSubscriptionStatus === CANCELLED;

  return (
      isCancelled ||
      isOneTime  ||
      (endDateAdjusted === true)
  );
}

export function transformBooking(booking) {
  const commonProperties = {
      initialCharge: formatCurrencyValue(booking.initialCharge, true),
      recurringCharge: formatCurrencyValue(booking.recurringCharge, true),
      rate: !booking.brokered ? `${formatCurrencyValue(booking.rate, true)}/${booking.durationType === 'MONTHLY' ? "month" : booking.durationType === 'DAILY' ? "day" : "week"}` : '',
      frequencyValue: formatFrequency(booking.frequency, booking.durationType),
      bookedDates: `${moment(booking.startDate).format("MM/DD/YYYY") || "N/A"} → ${showEndDate(booking) ? moment(booking.endDate).format("MM/DD/YYYY") : 'Until cancelled'}`
  };

  return {
      ...booking,
      ...commonProperties
  };
}

const useStyles: (theme: Theme) => { mainContainer: CSSStyleSheet, tabPanel: CSSStyleSheet } = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.86rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8.04rem',
      paddingRight: '8.04rem',
    }
  },
  tabPanel: {
    paddingLeft: '0',
    paddingRight: '0',
  },
}));

const ACTIVE_TAB = '0';
const PENDING_TAB = '1';
const INACTIVE_TAB = '2';
const PAYMENT_FAILED_TAB = '3';

const mapBuyerBookingTabToTabNumber = (buyerBookingTab: string) => {
  switch (buyerBookingTab) {
    case BuyerBookingTabs.ACTIVE:
      return ACTIVE_TAB;
    case BuyerBookingTabs.PENDING:
      return PENDING_TAB;
    case BuyerBookingTabs.INACTIVE:
      return INACTIVE_TAB;
    case BuyerBookingTabs.PAYMENT_FAILED:
      return PAYMENT_FAILED_TAB;
    default:
      throw new Error(`Invalid BuyerBookingTab: ${buyerBookingTab}`);
  }
};

const BookingsOverview = (props: { initialTab: string, account: Account, snackbarShowMessage: () => void, readPendingBooking: () => {} }) => {
  const { initialTab = '0', account, snackbarShowMessage, readPendingBooking } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const defaultSearch = urlParams?.get("bookingNumber") ? urlParams.get("bookingNumber") : "";

  const getDefaultTab = () => {
    if (urlParams) {
      let buyerBookingTab = BuyerBookingTabs[urlParams?.get("status")?.toUpperCase()];
      if (buyerBookingTab) {
        return mapBuyerBookingTabToTabNumber(buyerBookingTab);
      }
    }

    return initialTab;
  }

  const [currentTab, setCurrentTab] = useState(getDefaultTab());
  const [bookings: Booking[], setBookings] = useState([]);
  const [activeBookings: Booking[], setActiveBookings] = useState([]);
  const [inactiveBookings, setInactiveBookings] = useState([]);
  const [pendingBookings: Booking[], setPendingBookings] = useState([]);
  const [paymentFailedBookings, setPaymentFailedBookings] = useState([]);
  const [reloadAfterUpdate, setReloadAfterUpdate] = useState(true);
  const [searchQuery, setSearchQuery] = useState(defaultSearch);
  const classes = useStyles();

  const headerCells = [
    { id: 'orderNumber', label: 'BOOKING NUMBER' },
    { id: 'frequencyValue', label: 'FREQUENCY' },
    { id: 'bookedDates', label: 'BOOKED DATES' },
    { id: 'locationName', label: 'LOCATION' },
    { id: 'recurringCharge', label: 'MONTHLY CHARGE' },
    { id: 'status', label: 'STATUS' },
    { id: 'supplierCompanyName', label: 'SUPPLIER' },
    { id: 'numberOfSpaces', label: 'SPACES BOOKED' },
    { id: 'paymentSchedule', label: 'PAYMENT SCHEDULE' },
    { id: 'initialCharge', label: 'INITIAL CHARGE' },
    { id: 'paymentMethodDescription', label: 'PAYMENT METHOD' },
    { id: 'assetType', label: 'EQUIPMENT TYPE' },
    { id: 'createdOn', label: 'CREATED ON' },
  ];

  const headerCellsWithRate = headerCells.concat({ id: 'rate', label: 'RATE' });
  
  const hasPendingStatus: (booking: Booking) => boolean = useCallback((booking) => {
    const { status } = booking;

    return status === "Pending" || status === "Incomplete"
  }, []);

  const setBuyerBookingTab: (booking: Booking) => void = useCallback((booking) => {
    if (booking.active) {
      booking.buyerBookingTab = BuyerBookingTabs.ACTIVE;
    } else if (booking.status === "Pending" || booking.status === "Incomplete") {
      booking.buyerBookingTab = BuyerBookingTabs.PENDING;
    } else if (booking.status === 'Payment Declined') {
      booking.buyerBookingTab = BuyerBookingTabs.PAYMENT_FAILED;
    } else {
      booking.buyerBookingTab = BuyerBookingTabs.INACTIVE;
    }

    return booking;
  }, []);

  const allocateBookings = useCallback((bookings: Booking[]) => {
    setActiveBookings(bookings.filter(booking => booking.buyerBookingTab === BuyerBookingTabs.ACTIVE));
    setPendingBookings(bookings.filter(booking => booking.buyerBookingTab === BuyerBookingTabs.PENDING));
    setInactiveBookings(bookings.filter(booking => booking.buyerBookingTab === BuyerBookingTabs.INACTIVE));
    setPaymentFailedBookings(bookings.filter(booking => booking.buyerBookingTab === BuyerBookingTabs.PAYMENT_FAILED));
  }, []);

  const loadBookings = useCallback(() => {
    if (account?.id && reloadAfterUpdate) {
      getAllBuyerBookings(account.id, 'startDate','desc').then((response) => {
        const bookings = response.body?.map((booking) => {
          return transformBooking(booking);
        })
        setBookings(bookings || []);

        bookings.map((booking) => setBuyerBookingTab(booking));

        allocateBookings(bookings);
      }).catch((error) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error));
      });
    }
    setReloadAfterUpdate(false);
  }, [account, reloadAfterUpdate, hasPendingStatus, snackbarShowMessage, allocateBookings]);

  const handleTabChange = (_event, value) => {
    if (value !== undefined) {
      setCurrentTab(value);
    }
  };

  const handleSearchQueryChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleActionSuccess = () => {
    readPendingBooking();
    setReloadAfterUpdate(true);
  };

  useEffect(() => {
    loadBookings();
  }, [loadBookings]);
  // setSearchQuery to defaultSearch on load & trigger search change

  useEffect(() => {
    if (searchQuery) {
      let filterTokens = searchQuery.split(" ").map(value => value.toLocaleLowerCase());

      const filteredBookings: Booking[] = bookings.filter((booking: Booking) => filterTokens.reduce((found: boolean, token: string) => found ||
          booking.orderNumber?.toLocaleLowerCase().includes(token) ||
          booking.assetType?.toLocaleLowerCase().includes(token) ||
          booking.locationName?.toLocaleLowerCase().includes(token) ||
          booking.locationCity?.toLocaleLowerCase().includes(token) ||
          booking.locationState?.toLocaleLowerCase().includes(token) ||
          booking.locationAddressLine1?.toLocaleLowerCase().includes(token) ||
          booking.startDate.includes(token) ||
          booking.endDate.includes(token), false));
      allocateBookings(filteredBookings);
    } else {
      allocateBookings(bookings);
    }

  }, [searchQuery, bookings, hasPendingStatus, allocateBookings]);

  /** Set the tab to the default search only if there's an exact booking number match
   *  this Effect should only run on the initial load
   */
  useEffect(() => {
    if (defaultSearch && bookings?.length) {
      setCurrentTab(mapBuyerBookingTabToTabNumber(bookings.reduce((tab: string, booking: Booking) => tab || booking.buyerBookingTab, '')));
    }
  }, [defaultSearch, bookings]);

  return (
    <Container className={classes.mainContainer}>
      <Grid item>
        <Typography variant={'h5'} component={'h1'}>My Bookings</Typography>
      </Grid>
      <Grid item>
        <TabContext value={currentTab}>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs md={6}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label={'My Bookings Tabs'}
                textColor={'secondary'}
                variant={'fullWidth'}
              >
                <Tab value={ACTIVE_TAB} label={activeBookings.length ? `Active (${activeBookings.length})` : 'Active'} />
                <Tab value={PENDING_TAB} label={pendingBookings.length ? `Pending (${pendingBookings.length})` : 'Pending'} />
                <Tab value={INACTIVE_TAB} label={inactiveBookings.length ? `Inactive (${inactiveBookings.length})` : 'Inactive'} />
                <Tab value={PAYMENT_FAILED_TAB} label={paymentFailedBookings.length ? `Payment Failed (${paymentFailedBookings.length})` : 'Payment Failed'} />
              </Tabs>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                name={'searchQuery'}
                value={searchQuery}
                onChange={handleSearchQueryChange}
                label={'Search'}
                placeholder={'Enter your query here'}
                InputProps={{
                  endAdornment: <InputAdornment position={'end'}>
                    <Search />
                  </InputAdornment>
                }}
                variant={'standard'}
                fullWidth
              />
            </Grid>
          </Grid>

          <TabPanel value={ACTIVE_TAB} className={classes.tabPanel}>
            <ActiveBookings bookings={activeBookings} onActionSuccess={handleActionSuccess} account={account} headerCells={headerCellsWithRate} />
          </TabPanel>
          <TabPanel value={PENDING_TAB} className={classes.tabPanel}>
            <PendingBookings bookings={pendingBookings} account={account} onActionSuccess={handleActionSuccess} headerCells={headerCells}/>
          </TabPanel>
          <TabPanel value={INACTIVE_TAB} className={classes.tabPanel}>
            <BuyerBookingTab tab={BuyerBookingTabs.INACTIVE} bookings={inactiveBookings} onActionSuccess={handleActionSuccess} account={account} searchQuery={searchQuery} headerCells={headerCellsWithRate} />
          </TabPanel>
          <TabPanel value={PAYMENT_FAILED_TAB} className={classes.tabPanel}>
            <BuyerBookingTab tab={BuyerBookingTabs.PAYMENT_FAILED} bookings={paymentFailedBookings} onActionSuccess={handleActionSuccess} account={account} searchQuery={searchQuery} headerCells={headerCellsWithRate} />
          </TabPanel>
        </TabContext>
      </Grid>
    </Container>
  );
};

export default withSnackbar(BookingsOverview);
