import './css/bootstrap-datepicker3.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './react-router-modal/react-router-modal.css';
import './App.css';
import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tooltip/dist/react-tooltip.css'
import React, {Component} from 'react';
import {AppContext} from './context/app-context';
import TrackJsInitializer from './services/TrackJsInitializer';
import SessionPoller from './services/session/SessionPoller';
import SecurSpaceRouter from './routing/SecurSpaceRouter';
import {requestLoggedInAuthoritySource} from './services/session/session-requests';
import {getLandingRedirectPathForUser} from './routing/route-utils';
import Busy from './components/Busy';
import initializePendo from './util/PendoSnippet';
import {
  LOGIN_PATH,
  LoginRedirectNestedPathnameBlockList,
  LoginRedirectPathnameBlockList,
  LoginRedirectedNestedPathnameExplicitlyAllowedList
} from './components/constants/securspace-constants';
import TagManager from 'react-gtm-module'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import globalThemes from "./globalThemes";

const $ = window.$;
class SecurSpaceApp extends Component {
    constructor(props) {
        super(props);

        TrackJsInitializer.initialize();

        TagManager.initialize({
            gtmId: 'GTM-M7M8FTW'
        });

        this.state = {
            user: null,
            loadingUserDetails: true,
            pendingApprovalCount: 0,
            pendingInventoryCount: 0,
            lastSearchedLocation: {},
        };
    }

    componentDidMount() {
        if (!this.state.user || !this.state.user.username) {
            Busy.set(true);
            requestLoggedInAuthoritySource()
                .then((resp) => this.handleAlreadyLoggedIn(resp.body))
                .catch((err) => this.handleReloadFailure(err));
        } else {
            this.setState({ loadingUserDetails: false });
        }
        initializePendo(null, window.pendo.initialize);

        const {fancybox} = $(".fancybox");

        if (fancybox) {
            fancybox.fancybox({
                padding: 0,
                arrows: true,
                nextClick: true,
                autoPlay: false,
                playSpeed: 1500,
                openEffect: "elastic",
                openSpeed: "slow",
                closeEffect: "fade",
                closeSpeed: "fast",
                nextEffect: "elastic",
                nextSpeed: "slow",
                closeBtn: true,
            });
        }
    }

    componentWillUnmount() {
        SessionPoller.cancelPolling();
    }

    handleAlreadyLoggedIn = (account) => {
        this.setState({
            user: account,
            loadingUserDetails: false,
        });
        TrackJsInitializer.updateUserInfo(account);
        SessionPoller.beginPolling(this.handlePollingFail);
        Busy.set(false);
        // create user's meta data in Pendo by calling initialize();
        // https://developers.pendo.io/docs/?bash#functions
        initializePendo(this.state.user, window.pendo.initialize);
    };

    handleReloadFailure = () => {
        if (window.location.href !== window.location.origin &&
          !LoginRedirectPathnameBlockList.includes(window.location.pathname) &&
          !(
            LoginRedirectNestedPathnameBlockList.some((blockedPath) => window.location.pathname.startsWith(blockedPath)) &&
            !LoginRedirectedNestedPathnameExplicitlyAllowedList.includes(window.location.pathname)
          )
        ) {
            window.location.href = `${LOGIN_PATH}?redirect=${encodeURI(window.location.pathname + window.location.search)}`;
        }
        this.setState({ loadingUserDetails: false });
        Busy.set(false);
    };

    updatePendingApprovalCount = (count) => {
        this.setState({ pendingApprovalCount: count });
    }

    updatePendingInventoryCount = (count) => {
        this.setState({ pendingInventoryCount: count });
    }

    updateUser = (user, history, redirectPath: String, state: Object) => {
        this.setState({ user });
        TrackJsInitializer.updateUserInfo(user);
        // update user's meta data in Pendo by calling identify();
        // https://developers.pendo.io/docs/?bash#functions
        initializePendo(user, window.pendo.identify);

        if (!user) {
            SessionPoller.cancelPolling();
        }

        if (history) {
            let path = redirectPath || getLandingRedirectPathForUser(user);
            if (state) {
                history(path, state);
            } else {
                history(path);
            }
        }
    };

    handlePollingFail = () => {
        this.clearUser();
        /*
            It's not worth re-rendering the entire tree every time
            the url changes to provide react router's history object
            here, so we are just going to do redirect the old fashioned
            way
         */
        window.location.href = `${LOGIN_PATH}?timeout=true`;
    };

    setLastSearchedLocation = (search) => {
        this.setState({lastSearchedLocation: search})
    }

    clearUser = () => {
        this.setState({ user: undefined });
        TrackJsInitializer.updateUserInfo(null);
        SessionPoller.cancelPolling();
    };

    logout = () => {
        window.location.href = "/logout";
    };

    generateAppContext = () => {
        return {
            user: this.state.user,
            loadingUserDetails: this.state.loadingUserDetails,
            lastSearchedLocation: this.state.lastSearchedLocation,
            setLastSearchedLocation: this.setLastSearchedLocation,
            updateUser: this.updateUser,
            clearUser: this.clearUser,
            logout: this.logout,
            pendingApprovalCount: this.state.pendingApprovalCount,
            updatePendingApprovalCount: this.updatePendingApprovalCount,
            pendingInventoryCount: this.state.pendingInventoryCount,
            updatePendingInventoryCount: this.updatePendingInventoryCount
        };
    };

    render() {
        return (
            <AppContext.Provider value={this.generateAppContext()}>
                <ThemeProvider theme={globalThemes}>
                    <CssBaseline />
                    <SecurSpaceRouter />
                </ThemeProvider>
            </AppContext.Provider>
        );
    }
}

export default SecurSpaceApp;