import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {makeStyles} from '@mui/styles';
import VerifyBankAccountForm from "./VerifyBankAccountForm";
import {Theme} from "@mui/material";
import {parseCurrencyValue, validateCurrencyValue} from "../../util/PaymentUtils";
import {requestPartnerVerifyBankAccount} from "./request/payment-method-requests";
import {getErrorMessageForStandardResponse} from "../../util/NetworkErrorUtil";
import Busy from "../Busy";
import PropTypes from "prop-types";
import {useFlags} from "launchdarkly-react-client-sdk";

const useStyles: (theme: Theme) => {
  backgroundGrey100: CSSStyleSheet;
} = makeStyles((theme: Theme) => ({
  backgroundGrey100: {
    backgroundColor: theme.palette.grey[100]
  }
}));

const PartnerVerifyBankAccountDialog = ({
                                          fundingSourceId = '',
                                          accountId = '',
                                          open = false,
                                          onClose,
                                          handleLogout,
                                          onSuccess
                                        }) => {
  const [verifyPaymentMethodFields, setVerifyPaymentMethodFields] = useState({
    microDeposit1: 0,
    microDeposit2: 0,
  });
  const [errorMsg, setErrorMsg] = useState('');

  const classes = useStyles();
  const flags = useFlags();
  const isMicroDepositVerificationEnabled = flags.microdepositVerification;

  const resetForm = () => {
    setVerifyPaymentMethodFields({
      ...verifyPaymentMethodFields,
      microDeposit1: 0,
      microDeposit2: 0,
    });
    setErrorMsg('');
  };

  const handleClose = () => {
    resetForm();
    onClose();
  }

  const handleTextChange = (event) => {
    const {name, value} = event.target;
    switch (name) {
      case 'firstDepositAmount':
        if (!validateCurrencyValue(value)) return;
        setVerifyPaymentMethodFields({
          ...verifyPaymentMethodFields,
          microDeposit1: parseCurrencyValue(value || 0)
        });
        break;
      case 'secondDepositAmount':
        if (!validateCurrencyValue(value)) return;
        setVerifyPaymentMethodFields({
          ...verifyPaymentMethodFields,
          microDeposit2: parseCurrencyValue(value || 0)
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!fundingSourceId || !accountId) return;
    const body = {
      fundingSourceId: fundingSourceId,
      accountId: accountId,
      ...verifyPaymentMethodFields
    };
    Busy.set(true);
    requestPartnerVerifyBankAccount(body).then(() => {
      resetForm();
      onSuccess();
    }).catch(error => {
      if (error.response.status === 401) {
        handleLogout();
      }
      try {
        const rawErrorMsg = getErrorMessageForStandardResponse(
          error,
          "Failed to verify bank account. Please try again."
        );
        const errorObj = JSON.parse(rawErrorMsg);
        let message = 'Failed to verify bank account.';
        const embeddedErrors = errorObj._embedded.errors;
        if (embeddedErrors[0].path === '/amount1/value') {
          message += "Invalid amount for first deposit.";
        }
        if (embeddedErrors[1].path === '/amount2/value') {
          message += "Invalid amount for second deposit.";
        }
        setErrorMsg(message);
      } catch (e) {
        setErrorMsg("Failed to verify bank account. Please try again.");
      }
    }).finally(() => {
      Busy.set(false);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={'ss-bank-verification-title'}
      aria-describedby={'ss-bank-verification-steps-and-form'}
    >
      <DialogTitle id={'ss-bank-verification-title'}>
        <Typography variant={'h6'} component={'h2'}>Add Payment Method</Typography>
      </DialogTitle>
      {isMicroDepositVerificationEnabled &&
      <DialogContent id={'ss-bank-verification-steps-and-form'}>
        <VerifyBankAccountForm
          formId={'ss-verify-bank-form'}
          firstDepositAmountValue={verifyPaymentMethodFields.microDeposit1}
          secondDepositAmountValue={verifyPaymentMethodFields.microDeposit2}
          onSubmit={handleSubmit}
          onChange={handleTextChange}
        />
        {
          errorMsg &&
          <Typography variant={'body1'} color={'error'} align={'center'}>{errorMsg}</Typography>
        }
      </DialogContent>
      }
      <DialogActions className={classes.backgroundGrey100}>
        <Button color={'primary'} onClick={handleClose}>
          Cancel
        </Button>
        {isMicroDepositVerificationEnabled &&
        <Button color={'primary'} type={'submit'} onClick={handleSubmit} form={'ss-verify-bank-form'}>
          Verify Bank Account
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

PartnerVerifyBankAccountDialog.propTypes = {
  fundingSourceId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default PartnerVerifyBankAccountDialog;
