import React from 'react';
import {TextField, Typography} from "@mui/material";
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";
import {Theme} from "@mui/material";
import {formatCurrencyValue} from "../../util/PaymentUtils";

const useStyles: (theme: Theme) => {
  list: CSSStyleSheet,
  form: CSSStyleSheet,
} = makeStyles(() => ({
  list: {
    listStyle: 'disc inside',
    '& li': {
      paddingLeft: '.75em',
      display: 'list-item',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.43rem',
    marginTop: '1rem',
    padding: '0.57rem 0rem'
  }
}));

const VerifyBankAccountForm = ({formId = '', firstDepositAmountValue, secondDepositAmountValue, onChange, onSubmit}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant={'h6'} component={'h3'}>Enter Bank Account Deposit Amounts</Typography>
      <ul className={classes.list}>
        <li>
          <Typography variant={'body1'} component={'span'}>
            Two deposits were made into your bank account
          </Typography>
        </li>
        <li>
          <Typography variant={'body1'} component={'span'}>
            Deposit amounts were less than $0.10
          </Typography>
        </li>
        <li>
          <Typography variant={'body1'} component={'span'}>
            Deposit take 1-2 days to appear
          </Typography>
        </li>
      </ul>
      <form id={formId} onSubmit={onSubmit} noValidate className={classes.form}>
        <TextField
          label={'First Deposit Amount'}
          name={'firstDepositAmount'}
          value={formatCurrencyValue(firstDepositAmountValue ? firstDepositAmountValue : 0, true)}
          onChange={onChange}
          placeholder={'Enter the first micro deposit amount'}
          fullWidth
          inputMode={'decimal'}
          variant='standard'
        />
        <TextField
          label={'Second Deposit Amount'}
          name={'secondDepositAmount'}
          value={formatCurrencyValue(secondDepositAmountValue ? secondDepositAmountValue : 0, true)}
          onChange={onChange}
          placeholder={'Enter the second micro deposit amount'}
          fullWidth
          inputMode={'decimal'}
          variant='standard'
        />
      </form>
    </>
  );
};

const validateIntegerProps = (props, propName, componentName) => {
  if (props[propName] && !Number.isInteger(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Value must be an Integer.`,
    );
  }
};

VerifyBankAccountForm.propTypes = {
  firstDepositAmountValue: validateIntegerProps,
  formId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  secondDepositAmountValue: validateIntegerProps,
};

export default VerifyBankAccountForm;
