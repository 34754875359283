import React from 'react'
import { IoMdClose } from 'react-icons/io'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Drawer, List, ListItemText, ListItem, Grid, Collapse, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RegisterSubMenu from '../components/RegisterSubMenu';
import { useLocation } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    badge: {
        backgroundColor: 'red',
        color: 'white !important',
        borderRadius: '50%',
        width: "2em",
        height: "2em",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.8em'
    }
}));

const MobileMenu = ({ handleMenu, closeNavMenu, handleClick, leftNavOptions, rightNavOptions, expanded, showMenu, buyerSpaceUsageRedesign, pendingApprovalCount, pendingInventoryCount }) => {

    const classes = useStyles();
    const location = useLocation()

    let showRegisterSubMenu = (menuItem) => menuItem?.path === 'register';
    const leftSideNav = leftNavOptions?.filter((menu) => menu);


    function hasTrueValue(count, menuItem, path, subMenu) {
        if (subMenu) return count > 0 && menuItem?.badge && subMenu?.badge && subMenu.path === path;
        return count > 0 && menuItem.badge && menuItem.path === path;
    }


    return (
        <>
            <Drawer className={`ss-nav-drawer`} anchor="right" open={showMenu} onClose={closeNavMenu}>
                <Grid className='ss-close-btn'>
                    <IoMdClose size={20} onClick={closeNavMenu} />
                </Grid>
                <List component="nav" className='ss-nav-drawer-item'>
                    {leftSideNav?.map((menuItem, index) => (
                        menuItem?.submenus ? <React.Fragment key={index}>
                            <ListItem className={`ss-drawer-button ${location?.pathname === `/${menuItem?.path}` ? `ss-drawer-menu-item-highlighted ` : `ss-drawer-menu-item-unhighlighted`}`} button onClick={() => handleClick(menuItem.label)}>
                                <ListItemText primary={menuItem.label} />
                                {expanded === menuItem?.label ? <ExpandLess /> : <ExpandMore />}
                                {hasTrueValue(pendingApprovalCount, menuItem, 'bookings') && <Typography variant='body2' className={classes.badge} sx={{
                                    position: 'absolute',
                                    top: '-15%',
                                    right: '10%',
                                }}>
                                    {pendingApprovalCount}
                                </Typography>}
                                {hasTrueValue(pendingInventoryCount, menuItem, 'gate-management') && <Typography variant='body2' className={classes.badge} sx={{
                                    position: 'absolute',
                                    top: '-15%',
                                    right: '10%',
                                }}>
                                    {pendingInventoryCount}
                                </Typography>}
                            </ListItem>
                            <Collapse in={expanded === menuItem?.label} timeout="auto" unmountOnExit>
                                <List component="nav" className={classes.nested}>
                                    {menuItem?.submenus?.map((mItem, index) => (
                                        <ListItem key={index} className={`ss-drawer-button ${location?.pathname === `/${mItem?.path}` ? `ss-drawer-menu-item-highlighted ` : `ss-drawer-menu-item-unhighlighted`}`} onClick={() => handleMenu(mItem)} button>
                                            <ListItemText primary={buyerSpaceUsageRedesign  && mItem.label === 'Booked Space Calendar' ? 'Space Usage' : mItem.label} />
                                            {hasTrueValue(pendingApprovalCount, menuItem, 'approvals', mItem) && <Typography variant='body2' className={classes.badge}>{pendingApprovalCount}</Typography>}
                                            {hasTrueValue(pendingInventoryCount, menuItem, 'inventory-corrections', mItem) && <Typography variant='body2' className={classes.badge}>{pendingInventoryCount}</Typography>}
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </React.Fragment> : <ListItem className={`ss-drawer-button ${location?.pathname === `/${menuItem?.path}` ? `ss-drawer-menu-item-highlighted ` : `ss-drawer-menu-item-unhighlighted`}`} onClick={() => handleMenu(menuItem)} key={index} button>
                            <ListItemText primary={menuItem?.label} />
                            {hasTrueValue(pendingApprovalCount, menuItem, 'my-bookings') && <Typography variant='body2' className={classes.badge}>
                                {pendingApprovalCount}
                            </Typography>}
                        </ListItem>
                    ))}
                    {rightNavOptions?.map((menuItem, index) => (
                        menuItem?.submenus ?
                            <React.Fragment key={index}>
                                <ListItem className={`ss-drawer-button ${location?.pathname === `/${menuItem?.path}` ? `ss-drawer-menu-item-highlighted ` : `ss-drawer-menu-item-unhighlighted`}`} button onClick={() => handleClick(menuItem.label)}>
                                    <ListItemText primary={menuItem.label} />
                                    {expanded === menuItem?.label ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={expanded === menuItem?.label} timeout="auto" unmountOnExit>
                                    <List component="nav" className={classes.nested + ` ${showRegisterSubMenu(menuItem) ? 'ss-register-drawer-item' : ""}`}>
                                        {showRegisterSubMenu(menuItem) ?
                                            <RegisterSubMenu show={true} />
                                            : menuItem?.submenus?.map((mItem, index) => (
                                                <ListItem key={index} className={`ss-drawer-button ${location?.pathname === `/${mItem?.path}` ? `ss-drawer-menu-item-highlighted ` : `ss-drawer-menu-item-unhighlighted`}`} onClick={() => handleMenu(mItem)} button>
                                                    <ListItemText primary={buyerSpaceUsageRedesign  && mItem.label === 'Booked Space Calendar' ? 'Space Usage' : mItem.label} />
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                            </React.Fragment>
                            : <ListItem className={`ss-drawer-button ${menuItem?.path === "login" && "ss-login-btn"} ${location?.pathname === `/${menuItem?.path}` ? `ss-drawer-menu-item-highlighted ` : `ss-drawer-menu-item-unhighlighted`}`} onClick={() => handleMenu(menuItem)} key={index} button>
                                <ListItemText primary={menuItem?.label} />
                            </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    )
}

export default MobileMenu