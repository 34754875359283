import React, {Component} from 'react';
import {Navigate} from "react-router-dom";
import {
  isBuyerAccount,
  isSupplierAccount,
  isAdminAccount,
  hasAdminAccess,
  hasOwnerAccess,
  hasGateManagerAccess,
  hasGateClerkAccess,
  hasDispatcherAccess
} from "../util/AccountUtils";

class RedirectToHomePage extends Component {


    render() {
        return (
            <div>
                {
                    this.props.redirectToHome ?
                        this.props.attemptedUrlPath && this.props.attemptedUrlSearch && this.props.currentUserHasAccessToAttemptedUrlPath ?
                            <Navigate  to={{
                                pathname: this.props.attemptedUrlPath,
                                search: this.props.attemptedUrlSearch
                            }} />
                        : this.props.attemptedUrlPath && this.props.currentUserHasAccessToAttemptedUrlPath ?
                            <Navigate to={this.props.attemptedUrlPath}/>
                        : isSupplierAccount(this.props.account) && (!this.props.account?.userType || hasOwnerAccess(this.props.account)) ?
                            <Navigate to="/locations-profile"/>
                        : isSupplierAccount(this.props.account) && hasGateManagerAccess(this.props.account) ?
                            <Navigate to="/approvals"/>
                        : isSupplierAccount(this.props.account) && hasGateClerkAccess(this.props.account) ?
                            <Navigate to="/check-in"/>
                        : isBuyerAccount(this.props.account) && hasDispatcherAccess(this.props.account) ?
                            <Navigate to="/search"/>
                        : isBuyerAccount(this.props.account) && (!this.props.account?.userType || hasOwnerAccess(this.props.account)) ?
                            <Navigate to="/search"/>
                        : isAdminAccount(this.props.account) && hasAdminAccess(this.props.account) ?
                            <Navigate to="/login-as-account"/>
                        :
                            ''
                    :
                        ''
                }
            </div>
        )
    }
}

export default RedirectToHomePage;
