import React from 'react';
import {Box, Grid} from "@mui/material";
import { BookingLoadingSkeleton, Pagination } from '@securspace/securspace-ui-kit';
import BookingCardList from './BookingCardList';
import TextBox from '../bookingsOverview/TextBox';
import { withSnackbar } from '../hocs/withSnackbar';
import { headerCells } from './data';
import type { Booking } from '../../types/Booking';
import { statuses } from '../../views/supplier-booking/SupplierBooking';

const BookingTab = (props: { bookings: Array<Booking>, loading: boolean, page: Number,  setPage: () => {}, count: Number, status: string, rowsPerPage: Number, setRowsPerPage: () => {}}) => {
    const {bookings, loading, page, setPage, count, status, rowsPerPage, setRowsPerPage} = props;

  const infoBoxTitle = () => {
    switch(status) {
      case statuses.ACTIVE :
        return "An approved booking that is current or in the future.";
      case statuses.FAILED :
        return "A booking that has failed due to insufficient funds or a bank dispute. The booking cannot be used until the buyer submits payment.";
      case statuses.INACTIVE :
        return "A booking that is expired, canceled, or declined.";
      default :
        return "";
    }
  }

  const handleRowsPerPageChange = ({target}) => {
    setRowsPerPage(target.value)
  }

  const handlePageChange = (_, page) => {
    setPage(page)
  }

  return (
    <Box>
        {
            loading ? 
            <BookingLoadingSkeleton/>
            :
              <Box>
                  <TextBox title={infoBoxTitle()} />
                  <BookingCardList bookings={bookings} headerCells={headerCells} />
              </Box>
        }
        {
                    bookings?.length > 0 && <Grid item>
                        <Pagination
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            variant="tablePagination"
                        />
                    </Grid>
                }
    </Box>
  );
};

export default withSnackbar(BookingTab);
