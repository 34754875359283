import React, { useState } from 'react';
import { Button, Grid, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { invoiceCancelStatuses } from "../../../../constants/securspace-constants";
import Busy from "../../../../Busy";
import { requestPatchInvoiceStatus } from "../../../request/invoice-requests";
import { CallbackActions } from "../InvoiceActions";

const CancelInvoiceOptions = [
  {
    label: 'Uncollectible',
    status: invoiceCancelStatuses.UNCOLLECTIBLE,
    requireExplanation: false,
  },
  {
    label: 'Cancel - Term Changes',
    status: invoiceCancelStatuses.CANCEL_TERM_CHANGES,
    requireExplanation: false,
  },
  {
    label: 'Cancel - Booking Cancelled',
    status: invoiceCancelStatuses.CANCEL_BOOKING_CANCELLED,
    requireExplanation: false,
  },
  {
    label: 'Cancel - Billing Requirements',
    status: invoiceCancelStatuses.CANCEL_BILLING_REQUIREMENTS,
    requireExplanation: false,
  },
  {
    label: 'Cancel - Other',
    status: invoiceCancelStatuses.CANCEL_OTHER,
    requireExplanation: true,
  },
];

const CancelInvoiceModal = (props: {
  invoice: {transactionId: string},
  open: boolean,
  onInvoiceAction: (callbackActions: any) => void,
  onClose: (invoice: {transactionId: string}) => void,
}) => {
  const { invoice, open, onInvoiceAction, onClose } = props;
  const [cancelStatus, setCancelStatus] = useState('');
  const [requireExplanation, setRequireExplanation] = useState('');

  const handleClose = () => {
    onClose();
  };

  const onRadioChange = (event) => {
    const index = parseInt(event.target.value);
    setCancelStatus(CancelInvoiceOptions[index].status);
    setRequireExplanation(CancelInvoiceOptions[index].requireExplanation);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData).entries());
    const index = parseInt(formJson.refundRequestResponse);
    const status = CancelInvoiceOptions[index].status;

    const payload = { status };
    if (status === invoiceCancelStatuses.CANCEL_OTHER) {
      payload.statusNotes = formJson.reason;
    }

    const successCancelInvoice = () => {
      Busy.set(false);
      onClose(CallbackActions.SUCCESS, invoice, `Successfully cancelled invoice ${invoice.transactionNumber}`);
    };
    const failCancelInvoice= (error) => {
      Busy.set(false);
      onInvoiceAction(CallbackActions.ERROR, invoice, `${error}`);
    };

    Busy.set(true);
    requestPatchInvoiceStatus(invoice.transactionId, payload, successCancelInvoice, failCancelInvoice);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit
      }}
    >
      <DialogTitle>
        Respond to Refund Request
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          required
          name={"refundRequestResponse"}
          onChange={onRadioChange}
        >
          {CancelInvoiceOptions.map((cancelInvoiceOption, index) => {
            return <FormControlLabel
              value={index}
              label={cancelInvoiceOption.label}
              control={<Radio />}
            />
          })}
        </RadioGroup>
        {requireExplanation && <>
            <DialogContentText>
              Please provide an explanation for the cancellation
            </DialogContentText>
            <TextField
              required
              margin="dense"
              id="outlined-multiline-static"
              name="reason"
              label="Reason for Cancellation"
              helperText="Enter the reason this invoice is being cancelled."
              multiline
              rows={10}
              fullWidth
              variant="standard"
            />
          </>
        }

      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"center"}>
          <Button variant="text" onClick={handleClose} >Cancel</Button>
          {cancelStatus && <Button variant="contained" type="submit" >Cancel Invoice</Button>}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default CancelInvoiceModal;