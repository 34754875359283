import React from 'react'
import { Grid, Typography, Button } from '@mui/material'
import DownloadCSVButton from '../report/export/DownloadCSVButton';
import { getErrorMessageForNonStandardAndStandardResponse } from '../../util/NetworkErrorUtil';
import { getSupplierInvoicesCSVReport } from '../invoices/supplier-invoice/requests/supplier-invoices-requests';

const SupplierBookingTitle = ({
    count,
    title,
    setExpandedItems,
    setExpandedAll,
    isShowCollapseButton = false,
    handleExpandAll,
    expandedItems,
    snackbarShowMessage,
    query
}) => {
    return (
        <Grid item mt={4} mb={4} sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '1rem'
        }}>
            <Grid item sx={{
                display: 'flex',
                alignItems: 'center',
                gap: "1rem",
                flexWrap: 'wrap'
            }}>
                <Typography variant='h6' sx={{
                    textTransform: 'uppercase',
                }}>{title} (SHOWING ALL {count} RECORDS IN GROUP)</Typography>
                {isShowCollapseButton &&
                    <Button onClick={Object.keys(expandedItems).length > 0 ? () => {
                        setExpandedItems({})
                        setExpandedAll(false)
                    } : handleExpandAll} variant='text'>
                        {Object.keys(expandedItems).length > 0 ? 'Collapse All' : 'Expand All'}
                    </Button>
                }
            </Grid> 
            <DownloadCSVButton
                title='Download Report'
                reportUrl={getSupplierInvoicesCSVReport(query)}
                onError={(error) => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error, 'Failed to download report'))}
            />
        </Grid>
    )
}

export default SupplierBookingTitle
