const BuyerBookingTabs = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
});

const BuyerBookingTabVerbiage = Object.freeze({
  [BuyerBookingTabs.INACTIVE]: {
    title: 'A booking that is expired, canceled, or declined.',
    status: 'Inactive',
    paymentActionLabel: 'Update Payment Method',
  },
  [BuyerBookingTabs.PAYMENT_FAILED]: {
    title: 'A booking that has failed due to insufficient funds or a bank dispute. The booking cannot be used until the buyer submits payment.',
    status: 'Payment Denied',
    paymentActionLabel: 'Make Payment',
  },
});

export {
  BuyerBookingTabs,
  BuyerBookingTabVerbiage
}