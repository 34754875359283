import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography
} from "@mui/material";
import AddFundingSourceForm from "./AddFundingSourceForm";
import {SyntheticEvent} from "react";
import {createAccountFundingSource} from "./user/request/user-requests";
import Busy from "./Busy";
import {getErrorMessageForNonStandardAndStandardResponse} from "../util/NetworkErrorUtil";
import { makeStyles } from '@mui/styles';
import MicrodepositVerificationSteps from "./paymentMethods/MicrodepositVerificationSteps";
import {useFlags} from "launchdarkly-react-client-sdk";

const useStyles: (theme: Theme) => {
  backgroundGrey100: CSSStyleSheet;
} = makeStyles((theme) => ({
  backgroundGrey100: {
    backgroundColor: theme.palette.grey[100]
  }
}));

const MicrodepositVerificationStepsDialogContent = () => {
  return (
    <>
      <Typography variant={'h6'} component={'h2'}>Bank Account Verification Still Required</Typography>
      <MicrodepositVerificationSteps />
    </>
  );
};

const PartnerAddFundingSourceDialog = (props: {
  account: Object,
  open: boolean,
  onClose: () => void,
  handleLogout: () => void,
  onSuccess: () => void
}) => {
  const {account, open, onClose, handleLogout, onSuccess} = props;
  const [accountType, setAccountType] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [nickName, setNickName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const [showMicrodepositVerificationDialog, setShowMicrodepositVerificationDialog] = useState(false);
  const [response, setResponse] = useState();

  const flags = useFlags();
  const isMicroDepositVerificationEnabled = flags.microdepositVerification;

  const classes = useStyles();

  const resetForm = () => {
    setAccountType('');
    setRoutingNumber('');
    setAccountNumber('');
    setConfirmAccountNumber('');
    setNickName('');
    setErrorMsg('');
    setResponse(undefined);
  };

  const handleChange = (event: SyntheticEvent) => {
    const {name, value} = event.target;
    if (name !== 'accountType' && name !== 'nickName') {
      const regex = new RegExp(/\D/);
      if (regex.test(value)) {
        return;
      }
    }
    switch (name) {
      case 'accountType':
        setAccountType(value);
        break;
      case 'routingNumber':
        setRoutingNumber(value);
        break;
      case 'accountNumber':
        setAccountNumber(value);
        break;
      case 'confirmAccountNumber':
        setConfirmAccountNumber(value);
        break;
      case 'nickName':
        setNickName(value);
        break;
      default:
        break;
    }
  };

  const validateForm: () => boolean = () => {
    if (!accountType) {
      setErrorMsg('Please select an account type');
      return false;
    }
    if (!routingNumber || !accountNumber || !confirmAccountNumber || !nickName.trim()) {
      setErrorMsg('Please enter the required account information.');
      return false;
    }
    setErrorMsg('');
    return true;
  }

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    const formIsValid = validateForm();
    if (!formIsValid) {
      return;
    }
    const body = {
      id: account.id,
      newFundingSourceBankAccountType: accountType,
      newFundingSourceBankRoutingNumber: routingNumber,
      newFundingSourceBankAccountNumber: accountNumber,
      newFundingSourceAccountNickname: nickName
    }
    Busy.set(true);
    createAccountFundingSource(body).then((response) => {
      setShowMicrodepositVerificationDialog(isMicroDepositVerificationEnabled);
      setResponse(response.body);

      if (!isMicroDepositVerificationEnabled) {
        onSuccess(response);
        resetForm();
      }
    }).catch((error) => {
      if (error.response.status === 401) {
        handleLogout();
        return;
      }
      setErrorMsg(getErrorMessageForNonStandardAndStandardResponse(error));
    }).finally(() => Busy.set(false));
  };

  const handleCloseVerificationSteps = () => {
    setShowMicrodepositVerificationDialog(false);
    onSuccess(response);
    resetForm();
  }

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const ariaLabelledBy = 'ss-add-funding-source-title';
  const ariaDescribedBy = 'ss-funding-source-form';
  const formId = 'ss-add-funding-source-form';
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
    >
      <DialogTitle id={ariaLabelledBy} variant={'h6'} component={'h2'}>
        Add Bank Account
      </DialogTitle>
      <DialogContent id={ariaDescribedBy}>
        {
          showMicrodepositVerificationDialog && isMicroDepositVerificationEnabled ?
            <MicrodepositVerificationStepsDialogContent ariaDescribedBy={ariaDescribedBy} />
            :
            <>
              <AddFundingSourceForm
                formId={formId}
                accountType={accountType}
                routingNumber={routingNumber}
                accountNumber={accountNumber}
                confirmAccountNumber={confirmAccountNumber}
                nickName={nickName}
                onChange={handleChange}
                onSubmit={handleSubmit}
              />
              {
                errorMsg &&
                <Typography variant={'body1'} color={'error'} align={'center'}>{errorMsg}</Typography>
              }
            </>
        }
      </DialogContent>
      <DialogActions className={classes.backgroundGrey100}>
        {
          showMicrodepositVerificationDialog && isMicroDepositVerificationEnabled ?
            <Button color={'primary'} onClick={handleCloseVerificationSteps}>Close</Button>
            : <>
            <Button color={'primary'} onClick={handleClose}>Cancel</Button>
            <Button color={'primary'} type={'submit'} onClick={handleSubmit} form={formId}>Save Bank Account</Button>
          </>
        }
      </DialogActions>
    </Dialog>
  );
};

export default PartnerAddFundingSourceDialog;
