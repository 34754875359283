import request from "../../../../util/SuperagentUtils";
import { formatDateMMDDyyyy } from "../../../../util/DateUtils"; 

const BASE_URL = '/api/invoices/suppliers';

const getSupplierInvoices = (
    searchParams,
    onSuccess,
    onFail
) => {

    const { accountId, page, size, startDate, endDate, locationId, bookingNumber, invoiceNumber, transactionType, status, sortBy, sortDir } = searchParams;

    let pageParam = ["page", page || 0];
    let sizeParam = ["size", size || 10];

    let params = new URLSearchParams([pageParam, sizeParam]);

    if (startDate) {
        params.append("startDate", formatDateMMDDyyyy(startDate));
    }
    if (endDate) {
        params.append("endDate", formatDateMMDDyyyy(endDate));
    }
    if (locationId) {
        params.append("locationId", locationId)
    }
    if (bookingNumber) {
        params.append("bookingNumber", bookingNumber)
    }
    if (invoiceNumber) {
        params.append("transactionNumber", invoiceNumber)
    }
    if (transactionType) {
        params.append("transactionType", transactionType)
    }
    if (status) {
        params.append("status", status)
    }
    if (sortBy) {
        params.append("sortBy", sortBy)
    }
    if (sortDir) {
        params.append("sortDir", sortDir)
    }


    const result = request.get(`${BASE_URL}/${accountId}?${params}`);

    if (onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

const getSupplierInvoicesCSVReport = (searchParams) => {
      const { accountId, startDate, endDate, locationId, bookingNumber, invoiceNumber, transactionType, status, sortBy, sortDir } = searchParams;
      const queryParams = new URLSearchParams();
      if (startDate) queryParams.append("startDate", formatDateMMDDyyyy(startDate));
      if (endDate) queryParams.append("endDate", formatDateMMDDyyyy(endDate));
      if (locationId) queryParams.append("locationId", locationId);
      if (bookingNumber) queryParams.append("bookingNumber", bookingNumber);
      if (invoiceNumber) queryParams.append("transactionNumber", invoiceNumber);
      if (transactionType) queryParams.append("transactionType", transactionType);
      if (status) queryParams.append("status", status);
      if (sortBy) queryParams.append("sortBy", sortBy);
      if (sortDir) queryParams.append("sortDir", sortDir);
  
      return `${BASE_URL}/${accountId}/report?${queryParams.toString()}`;}

export {
    getSupplierInvoices,
    getSupplierInvoicesCSVReport
}