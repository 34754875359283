import React from 'react';
import type {Account} from "../../../../../types/Account";
import AddRefundCreditModal from "./AddRefundCreditModal";
import CreateRequestRefundModal from "./CreateRequestRefundModal";
import ManageRequestRefundModal from "./ManageRequestRefundModal";
import CancelInvoiceModal from "./CancelInvoiceModal";
import {InvoiceActionDialogs} from "../InvoiceActions";

type InvoiceActionsDialogWrapperProps = {
  activeDialog: String,
  account: Account,
  invoice: {transactionId: string, transactionNumber: string},
  onInvoiceAction: (callbackActions: any) => void,
  onClose: (callbackActions: any) => void,
};

const InvoiceActionsDialogWrapper: (props: InvoiceActionsDialogWrapperProps) => JSX.Element = ({
  activeDialog,
  account,
  invoice,
  onInvoiceAction,
  onClose
}) => {

  const onDialogClose = (...e) => {
    onClose && onClose(...e);
  }

  return (
    <>
      <AddRefundCreditModal open={activeDialog === InvoiceActionDialogs.ADD_REFUND_CREDIT} account={account} invoice={invoice} onInvoiceAction={onInvoiceAction} onClose={onDialogClose} />
      <CreateRequestRefundModal open={activeDialog === InvoiceActionDialogs.CREATE_REQUEST_REFUND} account={account} invoice={invoice} onInvoiceAction={onInvoiceAction} onClose={onDialogClose} />
      <ManageRequestRefundModal open={activeDialog === InvoiceActionDialogs.MANAGE_REQUEST_REFUND} account={account} invoice={invoice} onInvoiceAction={onInvoiceAction} onClose={onDialogClose} />
      <CancelInvoiceModal open={activeDialog === InvoiceActionDialogs.CANCEL_INVOICE} account={account} invoice={invoice} onInvoiceAction={onInvoiceAction} onClose={onDialogClose} />
    </>
  );
};

export default InvoiceActionsDialogWrapper;