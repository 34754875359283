import React from 'react';
import PaymentMethodListCard from "./PaymentMethodListCard";
import {ButtonBase, Grid, IconButton, Theme, Typography} from "@mui/material";
import {DeleteOutline} from "@mui/icons-material";
import {bookingCardStyles} from "../../views/booking-payment/style";
import { makeStyles } from '@mui/styles';
import {BankAccountStatus} from "../constants/securspace-constants";
import {useFlags} from "launchdarkly-react-client-sdk";

const useStyles: (theme: Theme) => {
  itemContainer: CSSStyleSheet,
  cardInfoContainer: CSSStyleSheet,
} = makeStyles(theme => ({
  itemContainer: {
    columnGap: '0.57em',
    padding: '1.43em',
    alignItems: 'center',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: `0.07rem solid ${theme.palette.grey[200]}`,
    },
  },
  cardInfoContainer: {
    gap: '1em',
    alignItems: 'center',
  },
}));

const PartnerFundingSourceItem = (props: {
  bankName: string,
  bankAccountType: string,
  nickName: string,
  img: any,
  onMenuAction: (actionName: string) => void
}) => {
  const {bankName, bankAccountType, nickName, status, img, onMenuAction} = props;
  const classes = useStyles();
  const bookingCardClasses = bookingCardStyles();

  const flags = useFlags();
  const isMicroDepositVerificationEnabled = flags.microdepositVerification;

  const handleAction = (actionName: string) => {
    onMenuAction(actionName);
  };

  return (
    <PaymentMethodListCard>
      <Grid item container alignContent={'center'} xs={12} sm className={classes.cardInfoContainer}>
        <img src={img} alt="Payment Card" className={bookingCardClasses.cardImg}/>
        <Typography variant={'h6'} color={'textPrimary'}>{bankName}</Typography>
      </Grid>
      <Grid item xs>
        <Typography variant={'h6'} color={'textPrimary'}>{bankAccountType}</Typography>
      </Grid>
      <Grid item xs>
        <Typography variant={'h6'} color={'textPrimary'}>{`Nick Name: ${nickName || 'n/a'}`}</Typography>
      </Grid>
      <Grid item xs>
        {
          status === BankAccountStatus.UNVERIFIED && isMicroDepositVerificationEnabled ?
            <ButtonBase onClick={() => onMenuAction('verify')}>
              <Typography variant={'h6'} color={'secondary'}>Click here to verify</Typography>
            </ButtonBase> :
            <Typography variant={'h6'} color={'textPrimary'}>{status === null ? 'Legacy' : status}</Typography>
        }
      </Grid>
      <Grid container item xs justifyContent={'flex-end'}>
        <IconButton onClick={() => handleAction('remove')}>
          <DeleteOutline/>
        </IconButton>
      </Grid>
    </PaymentMethodListCard>
  );
};

export default PartnerFundingSourceItem;
